import React from 'react'

function index() {
  return (
  <nav class="bg-ronin-beige border-ronin-black-200 ">
  <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
    <a href="/" class="flex items-center space-x-3 rtl:space-x-reverse">
        <span class="self-center text-2xl font-ronin whitespace-nowrap">RONIN AIRSOFT</span>
    </a>
    <button data-collapse-toggle="navbar-default" type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false">
        <span class="sr-only">Open main menu</span>
        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
        </svg>
    </button>
    <div class="hidden w-full md:block md:w-auto" id="navbar-default">
      <ul class="font-ronin flex flex-col p-4 md:p-0 mt-4 rounded-lg md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0">
        <li>
          <a href="/Inscription" class="block py-2 px-3 rounded md:bg-transparent md:p-0" aria-current="page">Inscripción incursión Pijao</a>
        </li>
        <li>
          <a href="/" class="block py-2 px-3 rounded md:bg-transparent md:p-0" aria-current="page">Inicio</a>
        </li>
        <li>
          <a href="/construction" class="block py-2 px-3 rounded md:hover:bg-transparent md:border-0 md:p-0 md:dark:hover:bg-transparent">Nosotros</a>
        </li>
        <li>
          <a href="/construction" class="block py-2 px-3 rounded md:hover:bg-transparent md:border-0 md:p-0 md:dark:hover:bg-transparent">Airsoft</a>
        </li>
        <li>
          <a href="/construction" class="block py-2 px-3 rounded md:hover:bg-transparent md:border-0 md:p-0 md:dark:hover:bg-transparent">Eventos</a>
        </li>
        <li>
          <a href="/construction" class="block py-2 px-3 rounded md:hover:bg-transparent md:border-0 md:p-0 md:dark:hover:bg-transparent">Contacto</a>
        </li>
      </ul>
    </div>
  </div>
</nav>

  )
}

export default index