import React from 'react'
import { Carousel } from "flowbite-react";
import './Home.css'
import Ronin1 from '../../assets/images/Ronin3.jpg'
import Ronin2 from '../../assets/images/Ronin2.jpg'
import Ronin3 from '../../assets/images/Ronin1.jpg'
import Ronin4 from '../../assets/images/Ronin4.jpg'
import logo from '../../assets/images/Ronin-logo.png'

let images = [
  Ronin1,
  Ronin2,
  Ronin3,
  Ronin4
]

function index() {
  return (
    <section className="w-full h-full pt-48">
      <div className="h-full w-full sm:h-64 xl:h-80 2xl:h-96 flex items-center justify-center mb-40">
        <div className='h-[43rem] w-3/4 flex items-center justify-center'>
          <Carousel className='w-full h-full relative object-contain'>
            {
              images.map((i) => (
                <img className='' src={i} alt="Ronin Team Members" />
              ))
            }
          </Carousel>
        </div>
      </div>

      <div className="w-full py-6 md:py-1 text-ronin-beige font-ronin">
          <div className="w-max-3/4 sm:4/5 md:w-3/4 lg:w-3/5 py-3 md:py-8 c-py-4 mx-auto flex flex-col md:flex-row items-center justify-items-center">
            <div className="">
              <img src={logo} title="" alt="" className="w-40 h-40" />
            </div>
            <div className="w-max-3/4 mx-auto sm:3/4 md:w-3/6 lg:w-3/4 c-w-3xl-66 text-center md:!text-start sm:pl-8 flex flex-col py-6">
              <h1 className="font-ronin text-4xl ">Ronin Airsoft Team</h1>
              <p className="font-ronin text-xl md:text-xl 2xl:text-3xl">LEALTAD, COMPROMISO Y DETERMINACION</p>
            </div>
          </div>
      </div>

      <div className="w-full h-dvh">
          <iframe title='training' frameborder="0" allowfullscreen="1"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              width="100%" height="100%"
              src="https://www.youtube.com/embed/eFI2XixM0X8?autoplay=0&amp;mute=0&amp;controls=0?&amp;subtitles=0?quality=1080p"
              id="widget2">
          </iframe>
      </div>
      <div className="w-full py-6 md:py-1 text-ronin-beige font-ronin flex flex-col md:flex-row items-center justify-items-center ">
        <p className="2xl:text-3xl m-20">Bienvienido a la pagina oficial de Ronin Airsoft Team, somos el equipo de Airsoft con más influencia a nivel Colombia</p>
      </div>
    </section>
  )
}

export default index