import React from 'react'
import logo from "../../assets/images/Ronin-logo.png"
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import MailIcon from '@mui/icons-material/Mail';

const index = () => {
  return (
    <main className="flex w-screen h-auto min-h-screen items-center justify-center bg-ronin-black px-6 py-24 sm:py-32 lg:px-8">
        <div className=" flex flex-col text-center text-ronin-beige items-center justify-center">
            <div className='w-40 h-40 flex items-center justify-center'>
                <img src={logo} alt='logo'></img>
            </div>
          <h1 className="mt-4 text-balance text-5xl font-ronin tracking-tight sm:text-7xl">
            Ronin Airsoft Team
          </h1>
          <p className="mt-6 text-pretty text-lg font-ronin sm:text-xl/8">
            Esta pagina se encuentra bajo construcción para brindarte una experiencia de Airsoft inolvidable
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <p className="text-sm font-ronin">
              Encuentranos en nuestras redes sociales <span aria-hidden="true">&rarr;</span>
              <a className='no-underline' target='_blank' rel="noreferrer" href='https://www.instagram.com/roninairsoftteam/?hl=es'><InstagramIcon /></a>
              <a className='no-underline' target='_blank' rel="noreferrer" href='https://www.facebook.com/p/Ronin-Airsoft-Team-Colombia-100063542203848/'><FacebookIcon /></a>
              <a className='no-underline' href='mailto:ronincolombia@gmail.com'><MailIcon /></a>
            </p>
          </div>
        </div>
      </main>
  )
}

export default index