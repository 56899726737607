import React from 'react'
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import MailIcon from '@mui/icons-material/Mail';
import YouTubeIcon from '@mui/icons-material/YouTube';

function index() {
  return (
    <footer class="flex flex-col space-y-10 justify-center m-10 text-ronin-black bg-ronin-beige font-ronin p-6">

        <nav class="flex justify-center flex-wrap gap-6 font-medium">
            <p>Síguenos en nuestras redes sociales</p>
        </nav>

        <div class="flex justify-center space-x-5">
            <a href="https://www.facebook.com/p/Ronin-Airsoft-Team-Colombia-100063542203848/" target="_blank" rel="noopener noreferrer">
                <FacebookIcon />
            </a>
            <a href="https://www.instagram.com/roninairsoftteam/?hl=es" target="_blank" rel="noopener noreferrer">
                <InstagramIcon />
            </a>
            <a href="mailto:ronincolombia@gmail.com" target="_blank" rel="noopener noreferrer">
                <MailIcon />
            </a>
            <a href="https://www.youtube.com/@roninairsoftcolombia" target="_blank" rel="noopener noreferrer">
                <YouTubeIcon />
            </a>
        </div>
        <p class="text-center font-medium">&copy; 2024 Ronin Airsoft Team. Todos los derechos reservados.</p>
        <p class="text-center text-xs">Creado por Santiago Cárdenas Ramírez.</p>
    </footer>
  )
}

export default index