import React from 'react'
import incursionPijao from '../../assets/images/IncursionPijao.jpg'

function index() {
  return (
    <div className="flex flex-col w-screen h-auto items-center justify-start font-ronin lg:px-8 text-ronin-beige">
        <div className="w-screen">
            <div className="mx-auto max-w-7xl pt-16 sm:pt-24">
                <div className="space-y-8 lg:space-y-0 lg:grid lg:grid-cols-12 lg:gap-8">
                    <div className="px-6 sm:text-center md:mx-auto md:max-w-2xl lg:col-span-6 lg:flex lg:items-center lg:text-left">
                        <div className="space-y-8">
                            <div className="space-y-4">
                                <div className="space-y-2">
                                    <span
                                        className="rounded-full uppercase bg-pink px-3 py-0.5 text-sm font-semibold leading-5">
                                        Evento
                                    </span>
                                    <h1 className="text-4xl font-bold tracking-tight sm:text-5xl md:text-6xl">
                                        <span className="sm:text-6xl">
                                        </span> INCURSIÓN PIJAO
                                    </h1>
                                </div>

                                <p className="text-base sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                                    Bienvenidos al primer evento nacional en el Departamento del Tolima, prepárate para una batalla épica junto a la etnia Pijao, en tierras ancestrales para recuperar su legado, ¿estás preparado?
                                </p>

                            </div>

                            <div className="border-t border-red-700"></div>

                            <div class="max-w-xl h-auto rounded-lg shadow">
                                <img class="rounded-lg" src={incursionPijao} alt="Incusrsion Piajo" />
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center w-full col-span-6">
                        <div className="px-6 h-2/5 w-full col-span-6 flex items-center mx-auto">
                            <div className="w-full h-full">
                                <div className="w-full h-full">
                                    <iframe title='incursiuon' frameborder="0" allowfullscreen="1"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        width="100%" height="100%"
                                        src="https://www.youtube.com/embed/QsU88U8yZ2g?autoplay=0&amp;mute=0&amp;controls=0?quality=1080p"
                                        id="widget2">
                                    </iframe>
                                </div>
                                <div className="flex justify-center items-center p-10">
                                    <div className="relative inline-flex  group">
                                        <div
                                            className="absolute transitiona-all duration-1000 opacity-70 -inset-px bg-gradient-to-r from-[#FFD700] via-[#ff0000] to-[#000000] rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt">
                                        </div>
                                        <a href="https://forms.gle/sxbYCsR3Tyq4L2dE8" target='_blank' rel="noreferrer" title="Formulario de inscripcion"
                                            className="relative inline-flex items-center justify-center px-8 py-4 text-lg font-bold text-ronin-beige transition-all duration-200 bg-gray-900 font-ronin rounded-xl focus:outline-none"
                                            role="button">¡Inscríbete ahora!
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div className="w-full h-[40rem] mx-auto max-w-7xl pt-16 sm:pt-24 flex flex-col items-center justify-center">
            <h1 className="text-4xl font-bold tracking-tight mb-9">
                <span className="sm:text-3x1 font-ronin">
                </span> Un mensaje importante para todas las mujeres de la comunidad del Airsoft
            </h1>
            <div className="w-[29.38rem] h-[30rem]">
                <iframe title='incursiuon' frameborder="0" allowfullscreen="1"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    width="100%" height="100%"
                    src="https://www.youtube.com/embed/mnWlMMCEYPI?autoplay=0&amp;mute=0&amp;controls=0?quality=1080p"
                    id="widget2">
                </iframe>
            </div>
        </div>
    </div>
  )
}

export default index