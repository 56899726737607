import './App.css';
import { BrowserRouter, Routes, Route } from "react-router";
import Navbar from './Components/Navbar'
import Footer from './Components/Footer'
import Construction from './Components/Construction'
import Inscripcion from './Components/Inscription'
import Home from './Components/Home'


function App() {
  return (
    <div className='h-full w-full bg-ronin-black'>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/construction" element={<Construction />} />
          <Route path="/Inscription" element={<Inscripcion />} />
          <Route path="/" element={<Home />} />
        </Routes>
        <Footer />
    </BrowserRouter>
    </div>
  );
}

export default App;
